import classNames from 'classnames/bind'
import styles from './Platforms.module.scss'


const cx = classNames.bind(styles)

function Platforms() {
    return(
        <div className={cx('container')}>
        </div>
    )
}

export default Platforms
