const Images = {

    logo_color: require('./LOGO_Color.png'),
    logo_white: require('./LOGO_White.png'),
    facebook: require('./Facebook.png'),
    tiktok: require('./Tiktok.png'),
    instagram: require('./Instagram.png'),
    zalo: require('./Zalo.png'),
    youtube: require('./Youtube.png'),
    unknown: require('./Unknow.jpg'),
    vietinbank: require('./VietinBank.jpg'),
    qr: require('./QR_Bank.jpg'),
    camera: require('./camera.jpg'),
    piano: require('./piano.jpg'),
    room: require('./room.jpg'),
    sheet: require('./sheet.jpg'),
    wave: require('./wave.png')
}

export default Images